<template>
  <div class="priceWrap">
    <div class="price">
      <div class="title">
        <p class="enTitle">PRICE DESCRIPTION</p>
        <p class="a1-1 a1 chTitle">價格說明</p>
      </div>
      <div class="content">
        <div class="contentTItle">
          永恆國際網路有限公司/<span>網頁設計價格說明</span>
        </div>
        <div class="tableGroup">
          <div class="tableWrap">
            <p
              class="tableTite"
              @click="show('table1')"
            >
              靜態網頁設計費用
            </p>
            <div
              class="tableContent"
              :style="showInfo('table1')"
            >
              <div
                class="wrap"
                ref="table1"
              >
                <p class="contentTitle">本公司報價如下</p>
                <p>客製化網站</p>
                <table>
                  <thead>
                    <tr>
                      <th>項目</th>
                      <th>單位</th>
                      <th>說明</th>
                      <th>價格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>首頁設計</td>
                      <td>1頁</td>
                      <td>客製化網頁，長度在980像素內</td>
                      <td>7500元</td>
                    </tr>
                    <tr>
                      <td>公司簡介</td>
                      <td>1頁</td>
                      <td>客製化網頁，長度在980像素內</td>
                      <td>2000元</td>
                    </tr>
                    <tr>
                      <td>網頁製作</td>
                      <td>1頁</td>
                      <td>客製化網頁，長度在980像素內</td>
                      <td>2500元</td>
                    </tr>
                  </tbody>
                </table>

                <p>套版網站</p>
                <table>
                  <thead>
                    <tr>
                      <th>項目</th>
                      <th>單位</th>
                      <th>說明</th>
                      <th>價格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>套版網頁設計</td>
                      <td>1套</td>
                      <td>以套版範例作為選擇，設計修改三次為限</td>
                      <td>14999元</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tableWrap">
            <p
              class="tableTite"
              @click="show('table2')"
            >
              程式設計收費介紹
            </p>
            <div
              class="tableContent"
              :style="showInfo('table2')"
            >
              <div
                class="wrap"
                ref="table2"
              >
                <p class="contentTitle">本公司報價如下</p>
                <table>
                  <thead>
                    <tr>
                      <th>項目</th>
                      <th>單位</th>
                      <th>說明</th>
                      <th>價格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>新聞訊息</td>
                      <td>1套</td>
                      <td>可以有後台自行發表新聞</td>
                      <td>6000元</td>
                    </tr>
                    <tr>
                      <td>商品展示模組</td>
                      <td>1套</td>
                      <td>可以有後台自行管理商品</td>
                      <td>20000元</td>
                    </tr>
                    <tr>
                      <td>聯絡我們</td>
                      <td>1式</td>
                      <td>聯絡表單程式</td>
                      <td>3500元</td>
                    </tr>
                    <tr>
                      <td>相本模組</td>
                      <td>1套</td>
                      <td>可以有後台自行管理"相本"</td>
                      <td>20000元</td>
                    </tr>
                    <tr>
                      <td>預約表單模組</td>
                      <td>1式</td>
                      <td>預約型式表單程式，適用報名與預約功能</td>
                      <td>7000元</td>
                    </tr>
                    <tr>
                      <td>網站IP紀錄器</td>
                      <td>1套</td>
                      <td>可以真實記錄IP</td>
                      <td>4000元</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tableWrap">
            <p
              class="tableTite"
              @click="show('table3')"
            >
              網路開店收費介紹
            </p>
            <div
              class="tableContent"
              :style="showInfo('table3')"
            >
              <div
                class="wrap"
                ref="table3"
              >
                <p class="contentTitle">本公司報價如下</p>
                <table>
                  <thead>
                    <tr>
                      <th>項目</th>
                      <th>單位</th>
                      <th>說明</th>
                      <th>價格</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>商品展示型</td>
                      <td>1式</td>
                      <td>以展示商品為主，網友透過統一表單聯繫</td>
                      <td>20000元</td>
                    </tr>
                    <tr>
                      <td>線上下單型</td>
                      <td>1式</td>
                      <td>網友每件商品可獨立分次下單</td>
                      <td>30000元</td>
                    </tr>
                    <tr>
                      <td>購物商城型</td>
                      <td>1式</td>
                      <td>透過購物車統計完成一次下單</td>
                      <td>40000元</td>
                    </tr>
                    <tr>
                      <td>自動化型</td>
                      <td>1式</td>
                      <td>網友直接透過網路代收公司線上金流結帳</td>
                      <td>50000元</td>
                    </tr>
                    <tr>
                      <td>線上刷卡型</td>
                      <td>1式</td>
                      <td>協助店家與銀行申請線上刷卡金流功能</td>
                      <td>70000元</td>
                    </tr>
                    <tr>
                      <td>企業客製化型</td>
                      <td>1式</td>
                      <td>完全依照企業營運需求設計</td>
                      <td>電話報價</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tableWrap">
            <p
              class="tableTite"
              @click="show('table4')"
            >
              活動方案
            </p>
            <div
              class="tableContent"
              :style="showInfo('table4')"
            >

              <div
                class="wrap"
                ref="table4"
              >

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showTable: '',
      showHeight: 0
    }
  },
  watch: {
    showTable () {
      if (this.showTable === '') {
        this.showHeight = 0
        return
      }
      const height = this.$refs[this.showTable].getBoundingClientRect().height
      this.showHeight = height
    }
  },
  methods: {
    showInfo (name) {
      if (name === this.showTable) {
        return {
          height: this.showHeight + 'px',
          padding: '12px 20px',
          'overflow-x': 'auto'
        }
      }
    },
    show (ref) {
      if (this.showTable === ref) {
        this.showTable = ''
        return
      }
      this.showTable = ref
    }
  }
}
</script>
<style lang="scss" scoped>
.chTitle {
  padding-bottom: 30px;
}
.priceWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.price {
  width: 98%;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.enTitle {
  color: #ff8b46;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 5px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .contentTItle {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 22px;
    width: 98%;
    padding: 8px 16px;
    border-radius: 3px;
    background-color: #f3f3f3;
    border: 1px solid #dedede;
    span {
      color: #ff8b46;
    }
  }
}
.tableGroup {
  width: 98%;
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 3px;
  .tableWrap {
    font-weight: bold;
    width: 100%;
    border: 1px solid #dedede;
    margin-bottom: 15px;
    .tableTite {
      background-color: #f3f3f3;
      font-size: 16px;
      padding: 12px 20px;
      // margin-bottom: 10px;
    }
    .contentTitle {
      font-size: 20px;
      padding-bottom: 12px;
    }
    .tableContent {
      transition: 0.7s ease-in-out;
      height: 0px;
      overflow: hidden;
      table {
        white-space: nowrap;
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        border-spacing: 0;
        border-collapse: collapse;
        border: 1px solid #dedede;
        margin-top: 12px;
        th {
          background-color: #fdf8e4;
          padding: 12px 20px;
          border-top: 1px solid #dedede;
          text-align-last: left;
        }
        tr {
          border-top: 1px solid #dedede;
          td {
            padding: 12px 20px;
          }
        }
      }
    }
  }
}
.openContent {
  padding: 10px 20px;
}
@media(max-width:500px){
  .contentTItle{
    font-size: 18px !important;
  }
}
</style>
